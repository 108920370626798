.hero {
  display: block;
  box-sizing: border-box;
  height: 75vh;
  background-image: url("../../assets//1.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: white;
  filter: blur(0);
}

.overlay {
  position: absolute;
  background-color: black;
  height: 75vh;
  width: 100%;
  opacity: 0.5;
}

.mainTitle {
  filter: blur(0);
  font-size: 50px;
}

.content {
  padding-top: 25vh;
  filter: blur(0);
}

@media only screen and (max-width: 600px) {
  .mainTitle {
    margin: 50px;
    font-size: 30px;
    margin-bottom: 15px;
  }
}
