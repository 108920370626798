.captionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 10px 0px 20px 0px;
}

.skeletonContainer {
  min-height: 30vh;
  margin-top: 0px;
}
