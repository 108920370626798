.container {
  display: grid;
  place-items: center;
}

.subheading {
  font-weight: normal;
}

.itemContainer {
  background-color: rgb(238, 238, 238);
  margin: 40px;
  width: 60%;
  display: grid;
  place-items: center;
  padding: 40px;
  border-radius: 15px;
}

@media only screen and (max-width: 600px) {
  .itemContainer {
    width: 70%;
  }
}
