.container {
  display: grid;
  place-items: center;
  padding-block: 100px;
}

.block {
  width: min(100%, 800px);
  padding-bottom: 40px;
}
