.container {
  margin-top: 10vh;
  display: grid;
  place-items: center;
}
.map {
  height: 350px;
  width: 400px;
}

.contactSection {
  font-size: 30px;
  font-weight: 450;
}

@media (max-width: 768px) {
  .map {
    height: 240px;
    width: 240px;
  }
  .contactSection {
    font-size: 20px;
    font-weight: 450;
  }
}
