.container {
  min-height: 50vh;
  margin-top: 8vh;
  display: grid;
  place-items: center;
}

.aboutText,
.messageContainer {
  width: min(80%, 800px);
}

.messageContainer {
  text-align: left;
  background-color: gainsboro;
  padding: 20px;
  border-radius: 15px;
  margin-block: 40px;
}

.signContainer {
  line-height: 0;
  text-align: right;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
