.container {
    display: grid;
    place-items: center;
    margin-top: 10dvh;
    padding-bottom: 100px;
}

.animation {
    width: 100%;
    height: 100%;
    max-width: 700px;
    max-height: 700px;
}