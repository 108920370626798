.mainContainer {
  display: grid;
  place-items: center;
  padding-block: 40px;
  width: min(90%, 1024px);
  margin: auto;
}

.contentContainer {
  margin: auto;
  width: 100%;
  display: grid;
  place-items: center;
}

.cardItem {
  border-radius: 20px;
  margin: auto;
  min-height: 400px;
}

.item {
  font-size: 1.2rem;
}
