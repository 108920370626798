.detailsContainer {
  line-height: 1.2;
  padding-top: 0.4375em;
  font-weight: 400;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  line-height: normal;
  text-align: left;
}

.agentContainer {
  height: 100%;
  text-align: left;
}

.agentContainer h2 {
  margin: 0px;
}

.agentContainer h4 {
  font-weight: 400;
}

.agentDetails {
  line-height: 1;
  padding-top: 5px;
}

.companyContainer {
  padding: 10px;
  text-align: left;
  padding-bottom: 25px;
  line-height: normal;
}

.companyContainer h4 {
  font-weight: 400;
  line-height: normal;
}

.paymentsContainer {
  padding: 10px;
}

@media (max-width: 600px) {
  .title {
    font-size: 30px;
  }
}
