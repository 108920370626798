.heading {
  font-weight: 100;
  font-size: 2rem;
  font-family: 'Spline Sans', sans-serif;
}

.container {
  min-height: 300px;
  background-color: rgb(238, 238, 238);
}

li {
  text-align: left;
}
