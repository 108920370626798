.detailsContainer {
  line-height: 1.2;
  padding-top: 0.4375em;
  font-weight: 400;
}

.workerContainer {
  padding: 10px;
  height: 50dvh;
  overflow: auto;
}
