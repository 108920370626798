.footer {
  background-color: rgb(3, 3, 98);
  display: grid;
  place-items: center;
  color: white;
  overflow: hidden;
}

.title {
  text-decoration: underline;
}

.linkItem {
  text-decoration: none;
  color: white;
  display: grid;
  place-items: center;
}

.contactInfo {
  line-height: 0.28;
  text-align: center;
}

.contactInfo > * {
  cursor: pointer;
}

.contactContainer {
  margin-top: 30px;
}

.linkItemText {
  cursor: pointer;
  text-align: left;
  width: 20%;
  margin-block: 4px;
  font-weight: normal;
}
