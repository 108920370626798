.skeletonContainer {
  height: 40vh;
  margin-top: 0px;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px 5px 0px;
  flex-wrap: wrap;
}
