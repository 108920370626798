.container {
  display: grid;
  place-items: center;
  background-color: rgb(238, 238, 238);
  padding: 100px;
}

.items {
  list-style: none;
  cursor: auto;
}

.items li:before {
  content: "✓ ";
}

.items li {
  padding-block: 5px;
  font-weight: 500;
}

.gList {
  width: min(90%, 800px);
  text-align: left;
  font-size: 1.2em;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 10px;
  }
  .gList {
    font-size: 1em;
  }
}
