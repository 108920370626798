.App {
  text-align: center;
}

h1 {
  font-family: "Merriweather", serif;
  font-size: 2em;
}

h2 {
  font-family: roboto;
}

.section-heading {
  font-weight: 100;
  font-size: 2rem;
  font-family: "Spline Sans", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(3, 3, 98);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 600px) {
  .section-heading {
    font-weight: 100;
    font-size: 1.5rem;
  }
}
