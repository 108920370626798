.container {
  padding: 40px;
  display: grid;
  place-items: center;
}

.compliance {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  gap: 10px;
  margin-top: 15px;
}

.credItem {
  border: 1px solid blue;
  padding: 25px;
  border-style: dashed;
  padding: 50px;
  border-radius: 15px;
  min-height: 50px;
  display: grid;
  place-items: center;
  margin-block: 10px;
}

@media only screen and (max-width: 600px) {
  .compliance {
    width: 100%;
    gap: 3px;
  }
}
